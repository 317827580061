<template>
  <div>
    <b-row class="match-height">
      <b-col xl="4" md="4" sm="4">
        <settings-card icon="ArrowUpCircleIcon"  link="inventoryreport" statistic="Sale Report" statistic-title="Sale Report" color="success" />
      </b-col>

      <b-col xl="4" md="4" sm="4">
        <settings-card icon="ArrowUpCircleIcon"  link="stockreport" statistic="Inventory Report" statistic-title="Inventory Report" color="info" />
      </b-col>
 
      <b-col xl="4" md="4" sm="4">
        <settings-card color="primary" icon="SettingsIcon" link="config" statistic="Accounting Configuration" statistic-title="Favorited" />
      </b-col>
 
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from "bootstrap-vue";

import useUserList from "../useSettings";

import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../settingsStoreModule";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
};
</script>
